
import { ref, onMounted, defineComponent, reactive, inject, watch } from 'vue';
import Map from 'ol/Map';
import 'ol/ol.css';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {buffer, Extent, boundingExtent } from 'ol/extent';
import { StyleMode, styleFuncCreator } from './mapstyle';
//@ts-ignore
import gpxParser from 'gpxparser';
import { ContentsDefine } from '../types';
import { Coordinate } from 'ol/coordinate';
import { createGpxLayer, loadContents } from './MapUtility';
import {defaults as defaultControls} from 'ol/control';
import BaseLayer from 'ol/layer/Base';
import PointsPopup from './PointsPopup.vue';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import datadefineStore, { DatadefineStore } from '../store/datadefine';
import Feature from 'ol/Feature';
import MapEvent from 'ol/MapEvent';
import GPX from 'ol/format/GPX';

export default defineComponent ({
    components: {
        PointsPopup,
    },
    setup() {
        const root = ref(null);
        const state = reactive({
            loaded: false as boolean,
            map: undefined as undefined | Map,
            popupPosition: undefined as Coordinate | undefined, // ポップアップ表示位置(非表示の時はundefined)
            popupIds: [] as string[],
            trackLayers: [] as VectorLayer[],
        });
        const datadefine = inject('datadefine') as DatadefineStore;
        console.log('datadefine', datadefine)

        onMounted(() => {
            const rootdiv = root.value;
            if (rootdiv === null) {
                console.warn('chart div not found.');
                return;
            }
            const div = (rootdiv as HTMLElement).querySelector('.mapchart') as HTMLElement;

            // コンテンツレイヤ生成
            const contentsSource = new VectorSource();
            
            const contentsLayer = new VectorLayer({
                source: contentsSource,
                style: styleFuncCreator(StyleMode.Contents),
                zIndex: 10,
            });

            // 地図生成
            const layers = [
                    new TileLayer({
                        source: new OSM(),
                        zIndex: 0,
                    })] as BaseLayer[];
            layers.push(contentsLayer);

            const map = new Map({
                target: div,
                layers,
                controls: defaultControls({attribution: true}),
                view: new View({
                    projection: 'EPSG:4326',
                    center: [0, 0],
                    zoom: 12,
                    maxZoom: 20,
                }),
            });
            state.map = map;

            loadContents(contentsSource)
            .then(() => {
                // とりあえず、コンテンツが収まるところにFitさせる
                let extent = contentsSource.getExtent();
                extent = buffer(extent, 0.2);
                map.getView().fit(extent);
            })

            watch(() => datadefine.trackList, (newVal, oldVal) => {
                // 新たに追加されたGPXについて描画する
                const oldIds = oldVal.map((def) => {
                    return def.track_id;
                });
                newVal.forEach((track) => {
                    if (oldIds.indexOf(track.track_id) !== -1) {
                        return;
                    }
                    // 指定のズーム範囲のレイヤを取得する
                    let targetLayer = state.trackLayers.find((layer): boolean => {
                        return track.min_zoom === layer.getMinZoom() && track.max_zoom === layer.getMaxZoom();
                    }) as VectorLayer;
                    // 存在しない場合は、新規レイヤ作成
                    if (targetLayer === undefined) {
                        targetLayer = new VectorLayer({
                            source: new VectorSource(),
                            minZoom: track.min_zoom,
                            maxZoom: track.max_zoom,
                            style: styleFuncCreator(StyleMode.Track),
                            zIndex: 1,
                        });
                        state.trackLayers = state.trackLayers.concat(targetLayer);
                        map.addLayer(targetLayer);
                    }

                    // gpxを追加
                    const gpx = new GPX();
                    const feature = gpx.readFeature(track.gpx);
                    if (feature !== null) {
                        targetLayer.getSource().addFeature(feature);
                    }
                })
            })

            const setSelect = (id: string) => {
                // 選択状態
                const select = (datadefine.selectItemId === id || datadefine.selectVideoId === id);
                const feature = contentsLayer.getSource().getFeatureById(id);
                feature.setProperties({
                    select,
                });
            }
            watch(() => datadefine.selectItemId, (newVal, oldVal) => {
                if (oldVal !== undefined) {
                    setSelect(oldVal);
                }
                if (newVal !== undefined) {
                    setSelect(newVal);
                }
            })
            watch(() => datadefine.selectVideoId, (newVal, oldVal) => {
                if (oldVal !== undefined) {
                    setSelect(oldVal);
                }
                if (newVal !== undefined) {
                    setSelect(newVal);
                }
            })

            map.on('moveend', (evt: MapEvent) => {
                const zoom = map.getView().getZoom();
                const ext = map.getView().calculateExtent();
                const convertFunc = (pos: number): number => {
                    if (pos > 180) {
                        return -(360 - pos);
                    } else if (pos < -180) {
                        return 360 + pos;
                    } else {
                        return pos;
                    }
                };
                ext[0] = convertFunc(ext[0]);
                ext[1] = convertFunc(ext[1]);
                ext[2] = convertFunc(ext[2]);
                ext[3] = convertFunc(ext[3]);
                console.log('moveend', zoom, ext)
                datadefine.loadTrackGpx(zoom, ext);
            })

            map.on('click', (evt: MapBrowserEvent) => {
                // クリック位置付近にあるシンボルを取得
                const points = [] as string[];
                map.forEachFeatureAtPixel(evt.pixel, (f) => {
                    const id = f.getId();
                    if (id !== undefined) {
                        points.push(f.getId() as string);
                    }
                });
                state.popupIds = points;
                if (points.length === 1 && datadefine.isVideo(points[0])) {
                    // 動画のみの場合は、直接動画再生
                    datadefine.setSelectItemId(points[0]);
                    state.popupPosition = undefined;
                } else if (points.length > 0) {
                    state.popupPosition = evt.coordinate;
                } else {
                    state.popupPosition = undefined;
                    datadefine.unsetSelectItemId();
                }
            });

            state.loaded = true;
        })

        return {
            root,
            state,
        }
    },

})

