
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import VectorSource from 'ol/source/Vector';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import Map from "ol/Map";
import { Coordinate } from 'ol/coordinate';
import { DatadefineStore } from '@/store/datadefine';

type Item = {
    id: string;
    type: 'video' | 'link';
    text: string;
    url: string;
}
/**
 * 指定のポイントに関する情報をリスト表示するポップアップ
 */
export default defineComponent ({
    props: {
        map: {
            required: true,
            type: Map,
        },
        source: {
            required: true,
            type: VectorSource,
        },
        // 情報表示するポイントID群
        points: {
            required: true,
            type: Array as () => string[],
        },
        // ポップアップ表示位置
        position: {
            required: true,
            type: Object as () => Coordinate,
        },
        // ポップアップ内のアイテムがクリックされた時に呼び出すコールバック
        click: {
            required: false,
            type: Function,
        }
    },
    setup(props) {
        const popup = ref<HTMLDivElement>();
        const datadefine = inject('datadefine') as DatadefineStore;

        const popupOverlay = new Overlay({
            positioning: OverlayPositioning.BOTTOM_CENTER,
            stopEvent: true,
            className: 'popup-overlay'
        });
        
        onMounted(() => {
            // ポイント用ポップアップ作成
            popupOverlay.setElement(popup.value);
            props.map.addOverlay(popupOverlay);
            popupOverlay.setPosition(props.position);

        })

        // ポップアップ位置設定
        watch(() => props.position, (newVal) => {
            popupOverlay.setPosition(newVal);
        })

        const contents = computed((): Item[] =>{
            return props.points.map((id: string) => {
                let text = '';
                let url = '';
                const type = datadefine.isVideo(id) ? 'video' : 'link';
                const content = datadefine.contentsMap[id];
                if (content !== undefined) {
                    text = content.title;
                    url = content.url;
                }
                return {
                    id,
                    type,
                    text,
                    url,
                };
            });
        });

        const onClick = (item: Item) => {
            if (item.type === 'link') {
                datadefine.setSelectItemId(item.id);
                window.open(item.url, item.id);
            } else {
                datadefine.setSelectItemId(item.id);
            }
            if(props.click !== undefined){
                props.click();
            }
        }

        return {
            props,
            popup,
            contents,
            onClick,
        }
    }
})
