import Feature from 'ol/Feature';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { StyleFunction } from 'ol/style/Style';
import * as olColor from 'ol/color';
import CircleStyle from 'ol/style/Circle';

export enum StyleMode {
    Track,
    Contents,
}
function colorWithAlpha(color: string, alpha: number) {
    const [r, g, b] = Array.from(olColor.asArray(color));
    return olColor.asString([r, g, b, alpha]);
}

function getColor(feature: Feature, mode: StyleMode): string {
    if (feature.getProperties().select === true) {
        return '#ff0000';
    }

    switch(mode) {
        case StyleMode.Track:
            return '#f5b461';
        default:
            return feature.getProperties()['type'] === 'video' ? '#0000ff' : '#41C9B4';
    }

}
export const styleFuncCreator = (mode: StyleMode): StyleFunction => {

    return function(feature: Feature, resolution: number) {
        let width = 5;

        const type = feature.getGeometry().getType();
        const isSelect = (feature.getProperties().select === true);
        const color = getColor(feature, mode);
        console.log('type', type);

        switch(type) {
            case "Point":
                if (feature.getProperties().area) {
                    return new Style({
                        image: new CircleStyle({
                            stroke: new Stroke({
                                color,
                                width: 1,
                            }),
                                fill: new Fill({
                                color: colorWithAlpha(color, 0.5),
                            }),
                            radius: 20,
                        }),
                    });
                } else {
                    return new Style({
                        image: new Icon({
                            anchor: [0.5, 46],
                            anchorXUnits: IconAnchorUnits.FRACTION,
                            anchorYUnits: IconAnchorUnits.PIXELS,
                            src: './icon/pin02.png',
                            color,
                            scale: Math.min(1, 0.005 / resolution),
                        }),            
                    });
                }
            case 'LineString':
            case 'MultiLineString':
                if (isSelect) {
                    width = 7;
                } else if(feature.getProperties()['type']) {
                    width = 7;
                }
                return new Style({
                    stroke: new Stroke({
                        color,
                        width,
                    })
                });
            case 'MultiPolygon':
                return new Style({
                    stroke: new Stroke({
                        color,
                        width: 1,
                    }),
                    fill: new Fill({
                        color: colorWithAlpha(color, 0.5),
                    })
                });
        }
    } as StyleFunction;
}
