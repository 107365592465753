<template>
  <div id="appBody">
    <h2 id="title"><img class="logo" src="@/assets/logo.png"/>Umiさん自転車旅の軌跡</h2>
    <Map id="map" v-if="state.loaded" />
    <div id="video">
      <div class="explain" v-if="state.videoId===undefined">
        <p>
          地図内の任意箇所をクリックしてください。
        </p>
        <p>
          <span class="video">■</span>動画再生
        </p>
        <p>
          <span class="link">■</span>記事リンク
        </p>
      </div>
      <Video :videoId="state.videoId" v-else/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, watch } from 'vue';
import Map from './components/Map.vue';
import Video from './components/Video.vue';
import { reactive } from 'vue'
import datadefineStore from './store/datadefine';

export default defineComponent({
  name: 'App',
  components: {
    Map,
    Video,
  },
  setup() {
    const state = reactive({
      loaded: false,
      videoId: undefined as string | undefined,
    });
    const datadefine = datadefineStore();
    provide('datadefine', datadefine);

    watch(() => datadefine.selectVideoId, (newVal) => {
        state.videoId = newVal;
    })

    datadefine.load().then(() => {
      state.loaded = true;
    })

    return {
      state,
    };
  }
});
</script>

<style lang="scss">
$title-height: 40px;
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: calc(100vh - #{$title-height});
}
#appBody {
  position: relative;
  height: 100%;
}
#title {
  margin: 0px;
  height: $title-height;
}
#map {
  width: 50%;
  float: left;
  height: calc(100% - #{$title-height});
}
#video {
  border: 1px solid #aaaaaa;
  width: calc(50% - 5px);
  height: calc(100% - #{$title-height});
  float: right;
}
img.logo {
  max-width: 40px;
  height: 30px;
  padding-right: 1rem;
}
.explain {
  padding: 10px;
  span {
    padding-right: 0.5rem;
  }
  .video {
    color: #0000ff;
  }
  .link {
    color: #41C9B4;
  }
}
</style>