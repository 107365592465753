<template>
    <div ref="popup" class="popup">
        <div class="item" v-for="item in contents" :key="item.id">
            <a href="#" @click="onClick(item)">
                <img src="icon/logo_symbol.svg" width="36" height="36" v-if="item.type==='link'" />
                <img src="icon/film.svg" width="36" height="36" v-else />
                <span class="text">
                    {{item.text}}
                </span>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import VectorSource from 'ol/source/Vector';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import Map from "ol/Map";
import { Coordinate } from 'ol/coordinate';
import { DatadefineStore } from '@/store/datadefine';

type Item = {
    id: string;
    type: 'video' | 'link';
    text: string;
    url: string;
}
/**
 * 指定のポイントに関する情報をリスト表示するポップアップ
 */
export default defineComponent ({
    props: {
        map: {
            required: true,
            type: Map,
        },
        source: {
            required: true,
            type: VectorSource,
        },
        // 情報表示するポイントID群
        points: {
            required: true,
            type: Array as () => string[],
        },
        // ポップアップ表示位置
        position: {
            required: true,
            type: Object as () => Coordinate,
        },
        // ポップアップ内のアイテムがクリックされた時に呼び出すコールバック
        click: {
            required: false,
            type: Function,
        }
    },
    setup(props) {
        const popup = ref<HTMLDivElement>();
        const datadefine = inject('datadefine') as DatadefineStore;

        const popupOverlay = new Overlay({
            positioning: OverlayPositioning.BOTTOM_CENTER,
            stopEvent: true,
            className: 'popup-overlay'
        });
        
        onMounted(() => {
            // ポイント用ポップアップ作成
            popupOverlay.setElement(popup.value);
            props.map.addOverlay(popupOverlay);
            popupOverlay.setPosition(props.position);

        })

        // ポップアップ位置設定
        watch(() => props.position, (newVal) => {
            popupOverlay.setPosition(newVal);
        })

        const contents = computed((): Item[] =>{
            return props.points.map((id: string) => {
                let text = '';
                let url = '';
                const type = datadefine.isVideo(id) ? 'video' : 'link';
                const content = datadefine.contentsMap[id];
                if (content !== undefined) {
                    text = content.title;
                    url = content.url;
                }
                return {
                    id,
                    type,
                    text,
                    url,
                };
            });
        });

        const onClick = (item: Item) => {
            if (item.type === 'link') {
                datadefine.setSelectItemId(item.id);
                window.open(item.url, item.id);
            } else {
                datadefine.setSelectItemId(item.id);
            }
            if(props.click !== undefined){
                props.click();
            }
        }

        return {
            props,
            popup,
            contents,
            onClick,
        }
    }
})
</script>

<style scoped>
.popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid white;
    bottom: 12px;
    left: -50px;
    min-width: 300px;
    user-select: none;
}
.popup:after, .popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
}
.popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
.popup:before {
    border-top-color: white;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.text {
    line-height: 36px;
    vertical-align: top;
}
</style>