<template>
    <div id="load-external-js">
    </div>
</template>

<script>
    export default {
        props: ['url'],
        mounted() {
            const tagName = 'script'
            const recaptchaScript = document.createElement(tagName)
            recaptchaScript.setAttribute('src', this.url)
            document.head.appendChild(recaptchaScript)
        }
    }
</script>
