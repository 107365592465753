import { ContentsDefine, TrackGpxDefine } from '@/types';
import { reactive } from 'vue';
//@ts-ignore
import gpxParser from 'gpxparser';
import { Extent } from 'ol/extent';

function loadAreaGeoJSON(defJson: {[id: string]: string}): Promise<{[id: string]: string}>{
    return new Promise<{[id: string]: string}>((resolve1) => {
        const geoJsonMap = {} as {[id: string]: string};
        Promise.all(Object.keys(defJson).map((id: string): Promise<void> => {
            return new Promise<void>((resolve2) => {
                const geoJsonUrl = defJson[id];
                fetch(geoJsonUrl)
                .then((response) => {
                    return response.text();
                }).then((geoJson) => {
                    geoJsonMap[id] = geoJson;
                    resolve2();
                })
            })
        })).then(() => {
            resolve1(geoJsonMap);
        })
    })
}

/**
 * ストア
 */
export default function datadefineStore() {
    const state = reactive({
        contentsMap: {} as {[id: string]: ContentsDefine},
        areaMap: {} as {[id: string]: string},      // エリアジオメトリ。val=GeoJSON
        trackList: [] as TrackGpxDefine[],

        gpxParser: [] as gpxParser[],
        selectItemId: undefined as string | undefined,  // 地図上でユーザが選択しているアイテムID
        selectVideoId: undefined as string | undefined, // 動画再生エリアに表示されている動画アイテムID
        isPlayingVideo: false,  // 動画再生中かどうか
      })

    const isVideo = (id: string) => {
        const url = state.contentsMap[id].url;
        const re = /^(.*)facebook(.*)\/videos\//;
        return re.test(url);
    }

    return {
        get contentsMap() {
            return state.contentsMap;
        },

        get trackList() {
            return state.trackList;
        },

        get areaMap() {
            return state.areaMap;
        },

        get gpxParser() {
            return state.gpxParser;
        },

        get selectItemId() {
            return state.selectItemId;
        },

        get selectVideoId() {
            return state.selectVideoId;
        },

        get isPlayingVideo() {
            return state.isPlayingVideo;
        },

        /**
         * 定義ロード
         */
        load: (): Promise<void> => {
            return new Promise<void>((resolve) => {
                fetch('/api/get_define.py')
                .then(function (response) {
                    return response.text();
                }).then(async function (jsonStr: string) {
                    const json = JSON.parse(jsonStr);
        
                    const contentsMap = {} as {[id: string]: ContentsDefine};
                    (json as ContentsDefine[]).forEach((def, index) => {
                            contentsMap['contents' + index] = def;
                    });
                    state.contentsMap = contentsMap;

                    // state.areaMap = await loadAreaGeoJSON(json.area);
        
                    resolve();
                });
            });
        },

        /**
         * 指定のズームLv., extentに該当するトラックGPXをロードする
         */
        loadTrackGpx: (zoom: number, ext: Extent): Promise<void> => {
            return new Promise<void>((resolve) => {
                // ロード済みID
                const idArr = state.trackList.map((track): string => {
                    return track.track_id + '';
                });
                const url = 'api/get_track_gpx.py?zoom=' + zoom + '&extent=' + ext.join(',') + '&loaded=' + idArr.join(',');
                fetch(url)
                .then((response) => {
                    return response.text();
                }).then((value) => {
                    const trackDefs = JSON.parse(value) as TrackGpxDefine[];
                    console.log('load', trackDefs);
                    const newTrackList = [] as TrackGpxDefine[];
                    Array.prototype.push.apply(newTrackList, state.trackList);
                    Array.prototype.push.apply(newTrackList, trackDefs);
                    state.trackList = newTrackList;
                });
            });
        },

        /**
         * 選択中のアイテムIDを設定する
         */
        setSelectItemId: (id: string) => {
            state.selectItemId = id;
            if (isVideo(id)) {
                // 動画の場合
                state.selectVideoId = id;
            }
        },

        unsetSelectItemId: () => {
            state.selectItemId = undefined;
        },

        /**
         * 指定のIDが動画かどうかを返す
         */
        isVideo: (id: string): boolean => {
            return isVideo(id);
        },

        /**
         * 動画再生開始時に呼び出す
         */
        startPlayingVideo: () => {
            state.isPlayingVideo = true;
        },

        /**
         * 動画再生が終了or停止した際に呼び出す
         */
        stopPlayingVideo: () => {
            state.isPlayingVideo = false;
        }
    }
}
export type DatadefineStore = ReturnType<typeof datadefineStore>;