
import { defineComponent, provide, watch } from 'vue';
import Map from './components/Map.vue';
import Video from './components/Video.vue';
import { reactive } from 'vue'
import datadefineStore from './store/datadefine';

export default defineComponent({
  name: 'App',
  components: {
    Map,
    Video,
  },
  setup() {
    const state = reactive({
      loaded: false,
      videoId: undefined as string | undefined,
    });
    const datadefine = datadefineStore();
    provide('datadefine', datadefine);

    watch(() => datadefine.selectVideoId, (newVal) => {
        state.videoId = newVal;
    })

    datadefine.load().then(() => {
      state.loaded = true;
    })

    return {
      state,
    };
  }
});
