import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import { Position, TimePosition, LonLatPosition, AreaPosition, ContentsDefine } from '../types';
import moment from 'moment';
import { StyleMode, styleFuncCreator } from './mapstyle';
import VectorLayer from 'ol/layer/Vector';
import GPX from 'ol/format/GPX';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import { inject } from 'vue';
import { DatadefineStore } from '@/store/datadefine';

export function createGpxLayer(gpxUrl: string, mode: StyleMode): VectorLayer {
    return new VectorLayer({
        source: new VectorSource({
            url: gpxUrl,
            format: new GPX(),
        }),
        style: styleFuncCreator(mode),
    });
}

/**
 * GPX情報内の指定の時刻範囲内のルートを抽出したFeatureを生成する
 * @param gpxParserArr 
 * @param timePosition 抽出時刻 
 */
async function extractTrackFeature(timePosition: TimePosition): Promise<Feature> {
    const end = moment(timePosition.startDate);
    const time = moment(timePosition.time, "hh:mm:ss");
    
    const startStr = end.format("YYYYMMDDHHmmss");
    const duration = time.format("HHmmss");
    const url = 'api/get_timetrack_gpx.py?start_date=' + startStr + '&duration=' + duration + '&timezone=0900';

    return new Promise<Feature>((resolve) => {
        fetch(url)
        .then((response) => {
            return response.text();
        }).then((gpxStr) => {
            const gpx = new GPX();
            const feature = gpx.readFeature(gpxStr);
            resolve(feature);
        });
    });
}

/**
 * 指定エリアのジオメトリを生成して返す
 * @param areaId 
 */
export function createAreaFeature(position: AreaPosition): Feature {
    const datadefine = inject('datadefine') as DatadefineStore;
    const geoJson = datadefine.areaMap[position.areaId];
    const feature = new GeoJSON().readFeatures(geoJson)[0];
    return feature;
}

/**
 * 指定位置を指すシンボルジオメトリを生成して返す
 * @param position 
 */
export function createPointFeature(position: LonLatPosition): Feature {
    const feature = new Feature({
        geometry: new Point([position.lon, position.lat]),
        population: 4000,
        rainfall: 500,
    });
    feature.setProperties({
        area: position.area,
    });
    return feature;
}

/**
 * 指定位置に対応するジオメトリを生成して返す
 * @param position 
 */
async function createFeatureByPosition(position: Position): Promise<Feature> {
    return new Promise<Feature>((resolve) => {
        if ('lat' in position) {
            const feature = createPointFeature(position);
            resolve(feature);
        } else if('areaId' in position) {
            const feature = createAreaFeature(position);
            resolve(feature);
        } else {
            extractTrackFeature(position)
            .then((feature) => {
                resolve(feature);
            });
        }
    });
}

/**
 * コンテンツFeatureを指定のsourceにロードする
 * @param source ロード先
 */
export async function loadContents(source: VectorSource) {
    const datadefine = inject('datadefine') as DatadefineStore;
    return Promise.all(Object.entries(datadefine.contentsMap).map(async(entry): Promise<void> => {
        const id = entry[0] as string;
        const def = entry[1] as ContentsDefine;
        const featurething = await createFeatureByPosition(def.position);
        if (featurething === null) {
            console.warn('contents could not be loaded.', id, JSON.stringify(def));
            return;
        }
        featurething.setId(id);
        featurething.setProperties({
            type: datadefine.isVideo(id) ? 'video' : 'link',
        })
        source.addFeature(featurething);
    }));
}