<template>
    <div class="frame">
        <div class="title">{{state.title}}</div>
        <div class="video" ref="root">
            <div id="fb-root"></div>
            <LoadExternalJS url="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"></LoadExternalJS>

            <div id="test" class="fb-video" :data-href="state.url" :data-height="state.height" data-show-text="false" data-autoplay="true">
                <div class="fb-xfbml-parse-ignore">
                    <blockquote :cite="state.url">
                        <a :href="state.url">How to Share With Just Friends</a>
                        <p>How to share with just friends.</p>
                        Posted by <a href="https://www.facebook.com/facebook/">Facebook</a> on Friday, December 5, 2014
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted, reactive, onUnmounted, inject } from 'vue';
import LoadExternalJS from '@/components/LoadExternalJS.vue';
import { DatadefineStore } from '@/store/datadefine';

type Props = {
  videoId: string;
};
let myVideoPlayer: any;
let timerH: number | undefined;
const subscriveHandlers= [] as any[];

function resetTimerH() {
    if (timerH !== undefined) {
        clearInterval(timerH);
    timerH = undefined;
    }
}

export default defineComponent({
    components: {
        LoadExternalJS,
    },
    props: {
        videoId: {
            type: String,
            required: true,
        }
    },
    setup(props: Props) {
        const datadefine = inject('datadefine') as DatadefineStore;
        const root = ref(null)
        const state = reactive({
            url: datadefine.contentsMap[props.videoId].url,
            title: datadefine.contentsMap[props.videoId].title,
            height: 0 as number,
        });

        const refreshVideo = () => {
            setTimeout(() => {
                //@ts-ignore
                FB.XFBML.parse();
                console.log('parse')
            }, 100)
        }

        watch(
            () => props.videoId,
            (newVal) => {
                state.url = datadefine.contentsMap[props.videoId].url;
                state.title = datadefine.contentsMap[props.videoId].title;
                refreshVideo();
            }
        )

        onMounted(() => {
            const rootDiv = root.value;
            if (rootDiv === null) {
                console.warn('chart div not found.');
                return;
            }
            state.height = Math.floor((rootDiv as HTMLElement).getBoundingClientRect().height);

            //@ts-ignore
            window.fbAsyncInit = () => {
                //@ts-ignore
                FB.init({
                    appId      : '350772326036855',
                    xfbml      : true,
                    version    : 'v3.2'
                });
                // Get Embedded Video Player API Instance
                //@ts-ignore
                FB.Event.subscribe('xfbml.ready', function(msg) {
                    if (msg.type === 'video') {
                        myVideoPlayer = msg.instance;
                        console.log('set instance', msg)

                        subscriveHandlers.push(
                            myVideoPlayer.subscribe('startedPlaying', () => {
                                console.log('start');
                                datadefine.startPlayingVideo();
                                timerH = setInterval(() => {
                                    const pos = myVideoPlayer.getCurrentPosition();
                                    console.log('current', pos);
                                }, 1000);
                            })
                        );
                        subscriveHandlers.push(
                            myVideoPlayer.subscribe('paused', () => {
                                console.log('pause');
                                datadefine.stopPlayingVideo();
                                resetTimerH();
                            })
                        );
                        subscriveHandlers.push(
                            myVideoPlayer.subscribe('finishedPlaying', () => {
                                console.log('finish');
                                datadefine.stopPlayingVideo();
                                resetTimerH();
                            })
                        );
                    }
                });
            };

            refreshVideo();

        })

        onUnmounted(() => {
            resetTimerH();
            subscriveHandlers.forEach((handler) => {
                handler.release();
            })
            
        })

        return {
            root,
            state,
        }
    }

})
</script>

<style scoped>
.frame {
    height: 100%;
}
.title {
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    background-color: #dddddd;
}
.video {
    height: calc(100% - 2rem);
}
</style>